// https://github.com/wrabit/vue-textarea-autogrow-directive

const TextareaAutogrowDirective = {
    name: 'autogrow',

    inserted (el, binding) {
        if (binding.value === false) {
            return
        }

        const minHeight = parseFloat(getComputedStyle(el).getPropertyValue('min-height'))

        const observe = (element, event, handler) => {
            element.addEventListener(event, handler, false)
        }

        const resize = () => {
            el.style.height = '1px'

            const border = el.style.borderTopWidth * 2
            const contentHeight = el.scrollHeight < minHeight ? minHeight : el.scrollHeight
            const height = contentHeight + border

            el.style.height = `${height}px`
        }

        // 0-timeout to get the already changed el
        const delayedResize = () => {
            window.setTimeout(resize, 0)
        }

        // When the textarea is being shown / hidden
        const respondToVisibility = (element, callback) => {
            const intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        callback()
                    }
                })
            }, {
                root: document.documentElement
            })

            intersectionObserver.observe(element)
        }

        respondToVisibility(el, resize)
        observe(el, 'change', resize)
        observe(el, 'cut', delayedResize)
        observe(el, 'paste', delayedResize)
        observe(el, 'drop', delayedResize)
        observe(el, 'input', resize)

        resize()
    }
}

// eslint-disable-next-line no-unused-vars
const VueTextareaAutogrowDirective = {
    install (Vue) {
        Vue.directive(TextareaAutogrowDirective.name, TextareaAutogrowDirective)
    }
}

// Automatic installation if Vue has been added to the global scope.
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(VueTextareaAutogrowDirective)
}

export default VueTextareaAutogrowDirective

export { TextareaAutogrowDirective }
