<template>
    <router-link
        :tag="componentTag"
        :to="matchRoute"
        class="match-link__wrapper"
    >
        <slot />
    </router-link>
</template>

<script>
import isMatchPageAvailable from '@/utils/is-match-page-available'

export default {
    name: 'MatchLink',

    props: {
        match: {
            type: Object,
            default: () => ({}),
            required: true
        }
    },

    computed: {
        componentTag () {
            return this.isMatchAvailable ? 'a' : 'span'
        },

        hasBothTeams () {
            return !!(this.match.home && this.match.away) || !!(this.match.homeTeam && this.match.awayTeam)
        },

        isMatchAvailable () {
            // if (this.isFootballMatch) {
            //     return true
            // }

            return isMatchPageAvailable({
                dateStart: this.match.startTime || this.match.dateStart || this.match.scheduledAtStamp
            }) && this.hasBothTeams
        },

        isFootballMatch () {
            return !this.match.sport?.code
        },

        matchRoute () {
            if (this.isFootballMatch) {
                return this.footballMatchRoute
            }

            return this.newSportMatchRoute
        },

        footballMatchRoute () {
            return {
                name: 'match',
                params: {
                    id: this.match.id
                }
            }
        },

        newSportMatchRoute () {
            return {
                name: 'match-new-sports',
                params: {
                    id: this.match.id,
                    sport: this.match.sport.code
                }
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.match-link {
    &__wrapper {
        cursor pointer
    }
}
</style>
