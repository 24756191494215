<template>
    <div
        v-show="show && link && text"
        :style="styles"
        class="promo"
        @click="onClick"
    >
        <div class="promo__wrapper">
            <img
                v-if="image"
                :src="image"
                :alt="text"
            >

            <span>{{ text }}</span>

            <DFP
                :placement="placement"
                overflow
                :refresh-timer="20"
                @receive="receiveMessage"
                @render="renderHandler"
            />
        </div>

        <UiTooltip
            drop="bottom"
            class="promo-tooltip"
            @click.native.stop
        >
            <InfoIcon class="promo-tooltip__icon" />
            <div
                slot="drop"
                :style="tooltipStyles"
                class="promo-tooltip__text"
            >
                {{ secondaryText }}
            </div>
        </UiTooltip>
    </div>
</template>

<script>
import DFP from '@/components/DFP/DFP.vue'
import InfoIcon from '@/assets/svg/info.icon.svg'
import adaptColor from '@/utils/adapt-color'

export default {
    name: 'DFPPromoBlockHeaderPrediction',

    components: {
        DFP,
        InfoIcon
    },

    data () {
        return {
            show: false,
            link: '',
            color: '',
            image: '',
            text: '',
            textColor: '',
            secondaryText: '',
            secondaryTextColor: '',
            placement: 'daily_bet'
        }
    },

    computed: {
        styles () {
            return {
                backgroundColor: this.color,
                color: this.textColor
            }
        },

        tooltipStyles () {
            return {
                color: this.secondaryTextColor
            }
        }
    },

    methods: {
        receiveMessage (data) {
            this.link = data.link
            this.color = adaptColor(data.color)
            this.image = data.image
            this.text = data.text
            this.textColor = adaptColor(data.textColor)
            this.secondaryText = data.secondaryText || data.disclaimer
            this.secondaryTextColor = adaptColor(data.secondaryTextColor)
            this.bookmaker = data.bookmakerName || 'unknown'
            this.show = true
            this.$pivik.event('dfp_show', this.placement, this.bookmaker)
        },

        onClick () {
            window.open(this.link, '_blank')
            this.$pivik.event('dfp_click', this.placement, this.bookmaker)
        },

        renderHandler () {
            this.link = ''
            this.color = ''
            this.image = ''
            this.text = ''
            this.textColor = ''
            this.secondaryText = ''
            this.secondaryTextColor = ''
            this.bookmaker = ''
        }
    }
}
</script>

<style lang="stylus" scoped>
    .promo
        padding 1rem 1.5rem
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        border-radius $radius-block $radius-block 0 0

        &__wrapper
            display flex
            flex-direction row
            align-items center
            justify-content center

            +layout-xs()
                flex-direction column
                align-items flex-start

            img
                margin-right 1rem
                width auto
                height 1.8rem

        &-tooltip
            cursor pointer

            &__icon
                display flex

            &__text
                padding 1rem 0
                max-width 26rem
                text-align center
                font-family $font-family-content
                font-size 1.4rem
</style>
