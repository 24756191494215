<template>
    <div
        :class="commentClass"
        class="prediction-comment link-decoration-none"
        @click="onClick"
    >
        <UiTextRoll
            :expand="expand"
            class="prediction-comment__text"
            :max-height="maxHeight"
            @expand="onExpand"
        >
            <p
                v-if="isMounted"
                v-dompurify-html="description.replace(/\n/gi, '<br>')"
                class="prediction-comment__description"
            />
            <p
                v-else
                class="prediction-comment__description"
                v-html="description.replace(/\n/gi, '<br>')"
            />
        </UiTextRoll>
    </div>
</template>

<script>

const LINE_HEIGHT_REM = 2.2
const NUMBER_OF_LINES = 10

export default {
    name: 'PredictionsItemComment',

    props: {
        description: {
            type: String,
            default: ''
        },

        path: {
            type: [String, Object],
            default: null
        },

        expand: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            isMounted: false
        }
    },

    computed: {
        commentClass () {
            return {
                'prediction-comment--has-link': this.path
            }
        },

        maxHeight () {
            return LINE_HEIGHT_REM * NUMBER_OF_LINES
        }
    },

    mounted () {
        this.$nextTick(() => {
            this.isMounted = true
        })
    },

    methods: {
        onClick () {
            if (this.path) {
                this.$router.push({ path: this.path })
                this.$emit('event', { name: 'prediction' })
            }
        },

        onExpand () {
            this.$emit('expand')
            this.$emit('event', { name: 'expand' })
        }
    }
}
</script>

<style lang="stylus" scoped>
.prediction-comment
    margin-top .8rem

    &--has-link
        cursor pointer

    &__text
        color $cl-text
        font-size 1.6rem
        line-height 2.2rem

    &__description
        margin 0
        font-family $font-family-content
        white-space pre-wrap
</style>
