<template>
    <div class="prediction-edit">
        <UiCheckbox
            v-model="top"
            class="prediction-edit__checkbox"
        >
            {{ $t('top-main') }}
        </UiCheckbox>

        <UiCheckbox
            v-model="topInMatch"
            class="prediction-edit__checkbox"
        >
            {{ $t('top-match') }}
        </UiCheckbox>

        <div class="prediction-edit__label">
            {{ $t('facts') }}
        </div>

        <div
            v-for="(fact, i) in facts"
            :key="i"
            class="prediction-edit__fact"
        >
            {{ fact }}
            <button
                class="prediction-edit__delete-fact-button"
                @click="deleteFact(i)"
            >
                <IconClose />
            </button>
        </div>

        <div
            v-if="isCreatingFact"
            class="prediction-edit__add-fact-wrapper"
        >
            <textarea
                ref="newFactInput"
                v-model="newFact"
                v-autogrow
                class="prediction-edit__textarea"
                @keydown="onKeyDownNewFact"
            />

            <button
                v-if="canSaveNewFact"
                class="prediction-edit__confirm-fact-button"
                @click="saveFact"
            >
                <IconConfirmed
                    class="prediction-edit__confirm-fact"
                />
            </button>

            <button
                class="prediction-edit__delete-fact-button"
                @click="cancelCreatingFact"
            >
                <IconClose />
            </button>
        </div>

        <button
            v-if="canCreateFact"
            class="prediction-edit__add-fact-button"
            @click="createFact"
        >
            +
        </button>

        <div class="prediction-edit__label">
            {{ $t('prediction-text') }}
        </div>

        <textarea
            v-model="text"
            v-autogrow
            class="prediction-edit__textarea prediction-edit__textarea-description"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "top-main": "Поднять в топ главной",
        "top-match": "Поднять в топ матча",
        "prediction-text": "Текст прогноза",
        "facts": "Добавить факт"
    },
    "en": {
        "top-main": "To top on home page",
        "top-match": "To top on match page",
        "prediction-text": "Prediction text",
        "facts": "Add fact"
    }
}
</i18n>

<script>
import { mapActions } from 'vuex'
import { TextareaAutogrowDirective } from '@/directives/autogrow'
import IconConfirmed from '@/assets/svg/confirmed-simple.icon.svg'
import areArraysDifferent from '@/utils/areArraysDifferent'

const FACTS_MAX_COUNT = 4


export default {
    name: 'PredictionsItemEditing',

    directives: {
        autogrow: TextareaAutogrowDirective
    },

    components: {
        IconConfirmed
    },

    props: {
        prediction: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            top: false,
            topInMatch: false,
            facts: [],
            text: '',

            isCreatingFact: false,
            newFact: ''
        }
    },

    computed: {
        shouldUpdatePrediction () {
            const { description, top, topInMatch, facts } = this.prediction

            const descriptionChanged = this.text !== description
            const isTopChanged = this.top !== top
            const isTopInMatchChanged = this.topInMatch !== topInMatch
            const didFactsChange = areArraysDifferent(this.facts, facts)

            return descriptionChanged || isTopChanged || isTopInMatchChanged || didFactsChange
        },

        canSaveNewFact () {
            return this.newFact && this.newFact.length <= 200
        },

        canCreateFact () {
            return !this.isCreatingFact && this.facts.length < FACTS_MAX_COUNT
        },

        isFootballPrediction () {
            return !this.prediction.match.sport?.code
        }
    },

    created () {
        const {
            description,
            topInMatch,
            top,
            facts
        } = this.prediction

        this.text = description
        this.topInMatch = topInMatch
        this.top = top
        this.facts = facts.slice()
    },

    methods: {
        ...mapActions({
            updatePrediction: 'admin/predictions/update'
        }),

        savePrediction () {
            if (this.shouldUpdatePrediction) {
                this.updatePrediction({
                    id: this.prediction.id,
                    description: this.text,
                    top: this.top,
                    topInMatch: this.topInMatch,
                    facts: this.facts,
                    isFootball: this.isFootballPrediction
                })
            }
        },

        createFact () {
            this.isCreatingFact = true

            this.$nextTick(() => {
                this.$refs.newFactInput.focus()
            })
        },

        onKeyDownNewFact (e) {
            if (e.keyCode === 13) {
                this.saveFact()
            }
        },

        saveFact () {
            if (this.newFact) {
                this.facts.push(this.newFact)
                this.isCreatingFact = false
                this.newFact = ''
            }
        },

        cancelCreatingFact () {
            this.newFact = ''
            this.isCreatingFact = false
        },

        deleteFact (index) {
            this.facts.splice(index, 1)
        }
    }
}
</script>

<style lang="stylus" scoped>
.prediction-edit
    padding-top .8rem

    &__checkbox
        margin .8rem .4rem

    &__textarea
        input()
        width 100%
        min-width 100%
        max-width 100%
        min-height 4.4rem

        &-description
            font-size 1.4rem
            line-height 1.4
            padding-right 5rem
            font-family $font-family-content

    &__label
        font-size 1.2rem
        line-height 1.6rem
        color $cl-secondary-dark
        margin-bottom .8rem
        margin-top 1.6rem

    &__add-fact-button
        button(5, 'contained', 'primary')
        width 4rem
        font-weight $font-weight-bold

    &__confirm-fact
        width 3rem
        height 3rem

    &__confirm-fact-button
        button(4, 'icon', 'success')
        height 2.4rem
        transition all 0.3s
        position absolute
        top .8rem
        right 3.8rem
        display flex
        align-items center

    &__add-fact-wrapper
        position relative

    &__fact
        position relative
        background-color $cl-secondary-light
        padding .8rem 3.2rem .8rem 1.4rem
        margin-bottom .8rem
        border-radius .8rem
        font-size 1.6rem
        line-height 2.2rem
        color $cl-text

    &__delete-fact-button
        button(4, 'icon', 'text')
        position absolute
        top .8rem
        right .8rem

</style>
