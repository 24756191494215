<template>
    <div class="report-button">
        <UiTooltip
            v-if="hasComplaint"
            drop="top"
        >
            <template #default>
                <span class="report-button__confirmed">
                    <IconConfirmed class="report-button__confirmed-icon" />
                </span>
            </template>

            <template #drop>
                <span class="prediction-footer__report-description">
                    {{ $t('reported') }}
                </span>
            </template>
        </UiTooltip>

        <UiTooltip
            v-else
            drop="top"
        >
            <template #default>
                <button
                    class="report-button__report"
                    @click="openReportPopup"
                >
                    <IconReport />
                </button>
            </template>

            <template #drop>
                <span class="prediction-footer__report-description">
                    {{ $t('report') }}
                </span>
            </template>
        </UiTooltip>
    </div>
</template>

<i18n>
{
    "ru": {
        "report": "Пожаловаться",
        "reported": "Отправлено"
    },
    "en": {
        "report": "Report",
        "reported": "Reported"
    }
}
</i18n>

<script>
import IconReport from '@/assets/svg/report-circle.icon.svg'
import IconConfirmed from '@/assets/svg/confirmed-simple.icon.svg'
import { mapActions } from 'vuex'

export default {
    name: 'ReportButton',

    components: {
        IconReport,
        IconConfirmed
    },

    props: {
        id: {
            type: String,
            required: true
        },

        objectType: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            hasComplaint: false
        }
    },

    methods: {
        ...mapActions('report', { createReport: 'create' }),

        openReportPopup () {
            this.$root.$emit('report', this.report.bind(this))
        },

        async report (reason) {
            await this.createReport({
                id: this.id,
                type: reason,
                objectType: this.objectType
            })

            this.hasComplaint = true
        }
    }
}
</script>

<style lang="stylus" scoped>
.report-button
    height 2.4rem

    &__report
        button(4, 'text', 'text')
        padding 0
        opacity .6
        transition all .3s

        &:hover
            color $cl-error

        &:active, &:focus
            opacity 1
            color $cl-error

    &__confirmed
        button(4, 'text', 'text')
        padding 0
        height 2rem
        width 2rem
        opacity .6
        transition all .3s
        border-radius 1rem
        color $cl-white
        background-color $cl-text

        &:hover
            background-color $cl-success

        &:active, &:focus
            opacity 1
            background-color $cl-success

    &__confirmed-icon
        height 2rem
        width 2rem

</style>
