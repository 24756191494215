export default {
    computed: {
        selectBet () {
            return this.$store.state.bet.select.odd
        }
    },
    methods: {
        /**
         * Устанавливает активный кэф
         * @param {Object} bet
         * @param {String} bet.id
         * @param {String} bet.rate
         * @param {String} bet.link
         * @param {String} bet.fullName
         * @param parentId
         */
        onBet (bet, parentId) {
            if (!this.$store.state.auth.isAuth) {
                this.$pivik.event('social', 'start', 'bet')

                return this.$root.$emit('auth')
            }

            return this.$store.dispatch('bet/select/set', {
                id: bet.id,
                rate: bet.rate,
                name: bet.fullName ? bet.fullName : bet.full_name,
                promo: bet.link,
                parent_id: parentId,
                isFootball: bet.isFootball
            })
        }
    }
}
