<template>
    <div>
        <DFP
            :placement="placement"
            @receive="messageHandler"
        />
        <a
            v-show="creative.isLoaded && isImageLoaded && creative.link"
            :href="creative.link"
            :style="buttonStyles"
            class="repeat-bet"
            rel="nofollow"
            target="_blank"
            @click="creativeClickHandler"
        >

            <span>
                {{ $t('bet_in') }}
            </span>

            <img
                :src="creative.image"
                :alt="creative.bookmaker"
                class="repeat-bet__image"
                @load="onImageLoaded"
            >
        </a>
    </div>
</template>

<i18n>
{
    "ru": {
        "bet_in": "Поставить в"
    },
    "en": {
        "bet_in": "Bet in"
    }
}
</i18n>

<script>
import DFP from '@/components/DFP/DFP.vue'
import adaptColor from '@/utils/adapt-color'

export default {
    name: 'DFPRepeatBetButton',

    components: {
        DFP
    },

    data () {
        return {
            placement: 'repeat_bet',
            isImageLoaded: true,

            creative: {
                isLoaded: true,
                link: '',
                image: '',
                bookmaker: '',
                buttonColor: '',
                marker: '',
                buttonBackgroundColor: ''
            }
        }
    },

    computed: {
        buttonStyles () {
            return {
                color: this.creative.buttonColor,
                backgroundColor: this.creative.buttonBackgroundColor
            }
        }
    },

    methods: {
        messageHandler (message) {
            if (message) {
                const creative = {}

                creative.link = message.link
                this.$emit('set-marker', message.marker)
                creative.image = message.image
                creative.bookmaker = message.bookmakerName || 'unknown'
                creative.buttonColor = adaptColor(message.buttonTitleColor)
                creative.buttonBackgroundColor = adaptColor(message.buttonBackgroundColor)
                creative.isLoaded = true

                this.creative = creative

                this.$pivik.event('dfp_show', this.placement, this.creative.bookmaker)
            }
        },

        creativeClickHandler () {
            this.$pivik.event('dfp_click', this.placement, this.creative.bookmaker)
        },

        onImageLoaded () {
            this.isImageLoaded = true
        }
    }
}
</script>

<style lang="stylus" scoped>

.repeat-bet
    button(5, 'contained', 'primary')
    display flex
    align-items center
    justify-content center
    min-width 12.8rem
    height 4rem
    margin 0 0 0 .8rem

    +layout-xs()
        width 100%
        padding 0 1.2rem
        margin .8rem 0 0 0

    &__image
        width 6.4rem
        margin-left .8rem

</style>
