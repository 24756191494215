<template>
    <div
        class="tip-shape"
        :class="classes"
    >
        <span
            v-for="(bet, i) in bets"
            :key="i"
            :class="getClass(bet.state)"
            class="tip-shape__point"
        />
    </div>
</template>


<script>
export default {
    name: 'BiShape',

    props: {
        bets: {
            type: Array,
            default: () => []
        },

        size: {
            type: String,
            default: ''
        }
    },

    computed: {
        classes () {
            if (this.size) {
                return `tip-shape--${this.size}`
            }

            return null
        }
    },

    methods: {
        getClass (state) {
            return {
                'is-win': state === 'WIN',
                'is-lose': state === 'LOSE',
                'is-return': state === 'RETURN'
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
    .tip-shape
        display flex
        align-items center

        &__point
            display block
            width .6rem
            height @width
            border-radius .2rem
            background $cl-secondary

            &:not(:last-child)
                margin 0 .2rem 0 0

            &.is-lose
                background-color $cl-error

            &.is-win
                background-color $cl-success-light

            &.is-return
                background-color $cl-secondary

        &--sm &__point
            width .4rem
            height .4rem
            margin 0 .1rem
</style>
