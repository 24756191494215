<template>
    <div class="predictions-facts">
        <div class="predictions-facts__title bi-overline">
            {{ title }}:
        </div>

        <p
            v-for="(fact, i) in facts"
            :key="i"
            class="predictions-facts__fact bi-subtitle-1"
        >
            {{ fact }}
        </p>
    </div>
</template>

<i18n>
{
    "ru": {
        "fact": "Факт",
        "facts": "Факты"
    },
    "en": {
        "fact": "Fact",
        "facts": "Facts"
    }
}
</i18n>

<script>
export default {
    name: 'PredictionsItemFacts',

    props: {
        facts: {
            type: Array,
            required: true
        }
    },

    computed: {
        hasMultipleFacts () {
            return this.facts.length > 1
        },

        title () {
            const key = this.hasMultipleFacts ? 'facts' : 'fact'

            return this.$t(key)
        }
    }
}
</script>

<style lang="stylus" scoped>
.predictions-facts
    display inline-block
    margin-top .8rem
    padding .8rem 1.2rem
    border-radius 1.6rem
    color $cl-text
    background-color $cl-background-common

    &__title
        margin-top 0
        margin-bottom .2rem

    &__fact
        margin-top 0
        margin-bottom .6rem

</style>
