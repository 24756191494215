<template>
    <div class="prediction-odd bi-header-3">
        <router-link
            v-if="path"
            :to="{ path: path }"
            :rel="rel"
            class="link-dark-inner"
            @click.native="onClick"
        >
            <span>{{ predictionName }}</span>
        </router-link>

        <span v-else>
            <span>{{ predictionName }}</span>
        </span>

        <span v-if="state !== 'OPEN'">
            <span class="prediction-odd__separator">&nbsp;•&nbsp;</span>{{ rate }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'PredictionsItemOdd',

    props: {
        predictionName: {
            type: String,
            default: ''
        },

        path: {
            type: [String, Object],
            default: null
        },

        rel: {
            type: String,
            default: ''
        },

        rate: {
            type: Number,
            default: 0
        },

        state: {
            type: String,
            default: ''
        }
    },

    methods: {
        onClick () {
            this.$emit('event', { name: 'prediction' })
        }
    }
}
</script>

<style lang="stylus" scoped>
.prediction-odd
    &__separator
        color $cl-secondary-dark

</style>
