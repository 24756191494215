<template>
    <div
        class="prediction bi-block"
        :class="classes"
    >
        <DFPPromoBlockHeaderPrediction v-if="prediction.sponsored && !hideSponsored" />

        <div class="prediction__header">
            <template v-if="isFootballPrediction">
                <UiAvatar
                    :src="homeTeam.logoSmall"
                    type="team"
                    size="xxs"
                    class="prediction__team-logo"
                />

                <UiAvatar
                    :src="awayTeam.logoSmall"
                    type="team"
                    size="xxs"
                    class="prediction__team-logo"
                />
            </template>

            <div
                v-else
                :style="{
                    '-webkit-mask-image': `url(${prediction.sport.logoUrl})`
                }"
                class="prediction__sport-logo"
            />

            <MatchLink
                :match="match"
                class="prediction__teams link-dark-inner bi-overline"
                @click.native="onGoToMatch"
            >
                {{ homeTeam.name }} - {{ awayTeam.name }}
            </MatchLink>

            <PredictionsItemEditButton
                v-if="isAdmin"
                :is-editing="isEditing"
                @edit="editPrediction"
                @save="savePrediction"
            />

            <ReportButton
                :id="prediction.id"
                :object-type="reportObjectType"
            />
        </div>

        <div class="prediction__body">
            <PredictionsItemOdd
                :prediction-name="prediction.fullName"
                :rel="rel"
                :path="predictionUrl"
                :rate="prediction.rate"
                :state="prediction.state"
                class="prediction__odd-item"
                @event="sendEvent"
            />

            <template v-if="!isEditing">
                <PredictionsItemFacts
                    v-if="prediction.facts.length"
                    :facts="prediction.facts"
                    :class="{
                        'prediction__facts--has-description': prediction.description
                    }"
                />

                <PredictionsItemComment
                    v-if="prediction.description"
                    :description="prediction.description"
                    :expand="expand"
                    :path="predictionUrl"
                    @expand="$emit('expand')"
                    @event="sendEvent"
                />
            </template>

            <PredictionsItemEditing
                v-else-if="isEditing"
                ref="editingBlock"
                :prediction="prediction"
            />
        </div>

        <div class="prediction__footer">
            <div class="prediction__user">
                <UiAvatar
                    v-if="!isProfilePage"
                    :src="prediction.user.avatar"
                    size="sm"
                    class="prediction__user-avatar"
                    @click.native="goToProfile"
                />

                <div
                    v-if="!isProfilePage"
                    class="prediction__user-wrapper"
                >
                    <router-link
                        :to="profileRoute"
                        class="prediction__user-name bi-subtitle-2 link-text-inner"
                        @click.native="onGoToProfile"
                    >
                        {{ prediction.user.name }}
                    </router-link>

                    <div class="prediction__stat">
                        <span class="prediction__roi bi-subtitle-2">
                            {{ prediction.user.roi }}% ROI
                        </span>

                        <ItemShape
                            v-if="userBets"
                            :bets="userBets"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div class="prediction__footer-right">
                    <template v-if="hasRepeat">
                        <button
                            class="prediction__button--repeat"
                            @click="onClickRepeat"
                        >
                            <IconRepeat />

                            <div class="prediction__for-word">
                                {{ $t('for') }}
                            </div>

                            <BetRate :rate="prediction.odds.rate" />
                        </button>

                        <DFPRepeatBetButton
                            v-if="hasBetRepeatAd"
                            @set-marker="setMarker"
                        />
                    </template>

                    <PredictionsItemFlag
                        v-else-if="isProfilePage"
                        :amount="prediction.amount"
                        :winning-amount="amountWin"
                        :state="prediction.state"
                    />
                </div>
            </div>
        </div>
        <span
            class="bi-subtitle-3"
            :style="{ paddingLeft: '1.6rem', display: 'flex', justifyContent: 'flex-end' }"
        >
            {{ marker }}
        </span>
    </div>
</template>

<i18n>
{
    "ru": {
        "for": "за"
    },
    "en": {
        "for": "for"
    }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import PredictionsItemFlag from '@/components/Predictions/Item/PredictionsItemFlag.vue'
import PredictionsItemOdd from '@/components/Predictions/Item/PredictionsItemOdd.vue'
import PredictionsItemComment from '@/components/Predictions/Item/PredictionsItemComment.vue'
import PredictionsItemEditing from '@/components/Predictions/Item/PredictionsItemEditing.vue'
import PredictionsItemFacts from '@/components/Predictions/Item/PredictionsItemFacts.vue'
import PredictionsItemEditButton from '@/components/Predictions/Item/PredictionsItemEditButton.vue'
import MatchLink from '@/components/Match/MatchLink.vue'
import DFPPromoBlockHeaderPrediction from '@/components/DFP/DFPPromoBlockHeaderPrediction.vue'
import DFPRepeatBetButton from '@/components/DFP/DFPRepeatBetButton.vue'
import ReportButton from '@/components/common/ReportButton/ReportButton.vue'
import ItemShape from '@/components/common/bi-shape/index.vue'
import BetRate from '@/components/Bet/BetRate.vue'
import IconRepeat from '@/assets/svg/repeat.icon.svg'
import hasFootballPredictionPage from '@/utils/has-prediction-page'
import Bet from '@/mixins/bet'
import adminStore from '@/store/modules/admin'

export default {
    name: 'PredictionsItem',

    components: {
        PredictionsItemFlag,
        PredictionsItemOdd,
        PredictionsItemComment,
        PredictionsItemEditing,
        PredictionsItemFacts,
        PredictionsItemEditButton,
        MatchLink,
        ReportButton,
        ItemShape,
        BetRate,
        IconRepeat,
        DFPRepeatBetButton,
        DFPPromoBlockHeaderPrediction
    },

    mixins: [Bet],

    storeModules: {
        admin: adminStore
    },

    props: {
        prediction: {
            type: Object,
            required: true
        },

        hideSponsored: {
            type: Boolean,
            default: false
        },

        isProfilePage: {
            type: Boolean,
            default: false
        },

        place: {
            type: String,
            default: 'feed'
        },

        index: {
            type: Number,
            default: 0
        },

        // Развернуть сообщение
        expand: Boolean,

        /**
         * Нужно для ceo, чтобы не считывались ссылки, находящиеся в блоке прогноза
         */
        rel: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            isEditing: false,
            marker: ''
        }
    },

    computed: {
        ...mapGetters('profile', ['isAdmin']),
        ...mapGetters('profile', ['my']),

        match () {
            return this.prediction.match
        },

        classes () {
            return {
                'prediction--is-win': this.prediction.state === 'WIN',
                'prediction--is-lose': this.prediction.state === 'LOSE',
                'prediction--is-return': this.prediction.state === 'RETURN'
            }
        },

        hasRepeat () {
            if (!this.prediction.odds || this.prediction.odds.disabled) {
                return false
            }

            return !this.isMyProfilePage
        },

        isMyProfilePage () {
            if (this.isProfilePage) {
                return this.isMyProfile
            }

            return false
        },

        isMyProfile () {
            return this.my && this.my.id === this.prediction.user.id
        },

        profileRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.prediction.user.id
                }
            }
        },

        homeRoute () {
            return {
                name: 'home',
                params: {
                    lang: this.$i18n.locale
                }
            }
        },

        reportObjectType () {
            return this.isFootballPrediction ? 'BET' : 'BET_NST'
        },

        predictionUrl () {
            if (!this.hasFootballPredictionPage) {
                return ''
            }

            if (this.isFootballPrediction) {
                if (this.prediction.path) {
                    return this.prediction.path.replace(/(en|ru)\//, '')
                }

                return this.prediction.path
            }

            return this.getNewSportPredictionUrl(this.prediction)
        },

        isFootballPrediction () {
            return !this.prediction.sport?.code
        },

        homeTeam () {
            return this.match.homeTeam?.team || {}
        },

        awayTeam () {
            return this.match.awayTeam?.team || {}
        },

        hasFootballPredictionPage () {
            if (this.isFootballPrediction) {
                return hasFootballPredictionPage(this.prediction)
            }

            return this.prediction.description.length > 100
        },

        shouldUpdatePrediction () {
            const { description, top, topInMatch } = this.prediction

            const descriptionChanged = this.text !== description
            const isTopChanged = this.top !== top
            const isTopInMatchChanged = this.topInMatch !== topInMatch

            return descriptionChanged || isTopChanged || isTopInMatchChanged
        },

        userBets () {
            return this.prediction.user?.betsForm
        },

        amountWin () {
            const { amountWin, state, amount, rate } = this.prediction
            let result = 0

            if (state === 'WIN') {
                result = amountWin
            } else if (['LOSE', 'RETURN'].includes(state)) {
                result = amount
            } else if (state === 'OPEN') {
                result = Math.floor(amount * rate)
            }

            return result
        },

        hasBetRepeatAd () {
            return this.prediction.top || this.prediction.topInMatch
        }
    },

    methods: {
        goToProfile () {
            this.onGoToProfile()

            this.$router.push(this.profileRoute)
        },

        setMarker (marker) {
            this.marker = marker
        },

        onGoToProfile () {
            this.sendEvent({ name: 'profile', label: this.prediction.user.id })
        },

        onGoToMatch () {
            let label = this.match.id

            if (this.match.webname) {
                label += `__${this.match.webname}`
            }

            this.$emit('event', { name: 'match', label })
        },

        sendEvent ({ name, label = '' }) {
            this.$pivik.event(this.place, name, label, this.index)
        },

        editPrediction () {
            this.isEditing = true
        },

        savePrediction () {
            this.$refs.editingBlock.savePrediction()

            this.isEditing = false
        },

        onClickRepeat () {
            this.sendEvent({ name: 'repeat' })
            this.onBet({
                isFootball: this.isFootballPrediction,
                ...this.prediction.odds
            }, this.prediction.id)
        },

        getNewSportPredictionUrl (prediction) {
            const route = this.$router.resolve({
                name: 'new-sport-predictions-item',
                params: {
                    sport: prediction.sport.code,
                    id: prediction.id
                }
            })

            return route.href
        }
    }
}
</script>

<style lang="stylus" scoped>
.prediction
    position relative
    overflow hidden

    &::after
        content ''
        position absolute
        top 0
        right 0
        height 100%
        border-right .3rem solid transparent

    &__header
        display flex
        align-items center
        border-bottom .1rem solid $cl-secondary-light
        padding .8rem .8rem .8rem 1.6rem
        height 4rem
        box-sizing border-box

        +layout-xs()
            padding .8rem

    &__team-logo
        &:not(:first-child)
            margin-left .4rem

    &__sport-logo
        width 1.6rem
        height 1.6rem
        background-color $cl-secondary
        mask-size cover
        mask-position center

    &__stat
        display flex
        color $cl-secondary-dark

    &__teams
        flex 1
        margin 0 .8rem

        white-space nowrap
        overflow hidden
        text-overflow ellipsis

    &__body
        padding 1.2rem 1.6rem 1.6rem

        +layout-xs()
            padding .8rem .8rem 1.2rem

    &__facts--has-description
        margin-bottom 1.6rem

    &__footer
        display flex
        align-items flex-end
        justify-content space-between
        font-size 1.2rem
        line-height 1.6rem

        +layout-xs()
            align-items initial
            flex-direction column

        &-right
            display flex
            box-sizing border-box
            padding 0 1.6rem 1.6rem

            +layout-xs()
                flex-direction column
                padding 0 .8rem .8rem
                width 100%

    &__user
        display flex
        align-items center
        padding 0 1.6rem 1.6rem
        flex 1

        +layout-xs()
            padding 0 0 .8rem .8rem

    &__user-name
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        width 100%

    &__user-wrapper
        display flex
        flex-direction column
        align-items baseline
        max-width 11rem

        +layout-xs()
            max-width calc(100vw - 8rem)

    &__user-avatar
        cursor pointer
        margin-right .8rem

    &__button--repeat
        button(5, 'outlined', 'primary')
        border-color $cl-secondary
        display flex
        align-items center
        justify-content center
        min-width 12.8rem
        width 23.2rem

        +layout-sm()
            width 18rem

        +layout-xs()
            width 100%
            padding 0 1.2rem

    &__for-word
        margin 0 .4rem

    &__roi
        margin-right .8rem
        white-space nowrap

    &--is-win
        &::after
            border-right-color $cl-success-light

    &--is-lose
        &::after
            border-right-color $cl-error-light

    &--is-return
        &::after
            border-right-color $cl-secondary

</style>
