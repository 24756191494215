<template>
    <button
        v-if="isEditing"
        class="admin-button admin-button--save"
        @click="$emit('save')"
    >
        {{ $t('save') }}
        <IconSave class="admin-button__icon" />
    </button>

    <button
        v-else
        class="admin-button admin-button--edit"
        @click="$emit('edit')"
    >
        {{ $t('edit') }}
        <IconEdit class="admin-button__icon" />
    </button>
</template>

<i18n>
{
    "ru": {
        "save": "Сохранить",
        "edit": "Редактировать"
    },
    "en": {
        "edit": "Edit",
        "save": "Save"
    }
}
</i18n>

<script>
import IconSave from '@/assets/svg/save.icon.svg'
import IconEdit from '@/assets/svg/edit.icon.svg'

export default {
    name: 'PredictionsItemEditButton',

    components: {
        IconSave,
        IconEdit
    },

    props: {
        isEditing: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="stylus" scoped>
.admin-button
    &__icon
        margin-left .8rem

    &--save
        button(4, 'contained', 'success')
        background-color rgba($cl-success-light, .1)
        color $cl-success-light

    &--edit
        button(4, 'contained', 'text')
        background-color rgba($cl-text-dark, .1)
        color $cl-text-dark

.admin-button
    margin-right .8rem
    display flex
    align-items center
    height 2.4rem
    line-height 2.4rem
    letter-spacing 0

</style>
