<template>
    <div
        :class="headerClass"
        class="prediction-flag"
    >
        <div class="prediction-flag__winning-amount">
            {{ winningAmountString | number }} <span class="bi-betty" />
        </div>

        <div class="prediction-flag__amount">
            {{ amount | number }} <span class="bi-betty" />
        </div>
    </div>
</template>

<script>
import number from '@/filters/number'

export default {
    name: 'PredictionsItemFlag',

    filters: {
        number
    },

    props: {
        state: {
            type: String,
            required: true
        },

        amount: {
            type: Number,
            required: true
        },

        winningAmount: {
            type: Number,
            required: true
        }
    },

    computed: {
        headerClass () {
            return {
                'prediction-flag--win': this.state === 'WIN',
                'prediction-flag--lose': this.state === 'LOSE',
                'prediction-flag--return': this.state === 'RETURN'
            }
        },

        winningAmountString () {
            let prefixSymbol = ''

            if (['WIN', 'OPEN'].includes(this.state)) {
                prefixSymbol = '+'
            }

            if (this.state === 'LOSE') {
                prefixSymbol = '-'
            }

            return `${prefixSymbol} ${this.winningAmount}`
        }
    }
}
</script>

<style lang="stylus" scoped>
.prediction-flag
    display flex
    font-weight $font-weight-bold
    font-size 1.4rem
    line-height 1.9rem

    &--win
        .prediction-flag__amount
            background-color rgba($cl-success, .1)
            color $cl-success

        .prediction-flag__winning-amount
            color $cl-success

    &--lose
        .prediction-flag__amount
            background-color rgba($cl-error, .1)
            color $cl-error

        .prediction-flag__winning-amount
            color $cl-error

    &--return
        .prediction-flag__amount
            background-color rgba($cl-secondary-dark, .1)
            color $cl-secondary-dark

        .prediction-flag__winning-amount
            color $cl-secondary-dark

    &__amount
        padding .8rem 1.2rem
        border-radius $radius-block
        font-weight $font-weight-bold
        color $cl-primary
        background rgba($cl-primary, .1)

    &__winning-amount
        padding .8rem 1.2rem .8rem 0
        font-weight $font-weight-bold
        color $cl-primary
</style>
